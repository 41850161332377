import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ListBuilders from './pages/list-builders';
import CreateListBuilder from './pages/CreateListBuilder/CreateListBuilder';
import { useAuth0 } from '@auth0/auth0-react';

import './App.css';

const App = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
  console.log(logout);
  if (user) {
    localStorage.setItem('name', user?.nickname);
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ListBuilders />} />
          <Route path="/list_builders" element={<ListBuilders />} />
          <Route path="/list_builders/new" element={<CreateListBuilder />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return <button onClick={() => loginWithRedirect()}>Log in</button>;
  }
};

export default App;

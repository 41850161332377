import React from 'react';
import AutoComplete from '../autocomplete';

const Search = () => {
  return (
    <div>
      <AutoComplete suggestions={['Apple', 'Orange', 'Mango']} />
    </div>
  );
};

export default Search;

import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dataFields, { BASIC_FIELD_LIST } from './data-fields';
import msaterDataApi from '../../../../services/masterDataApi';
import { REDUCER_STATE } from '../../reducer';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? 'lightblue' : 'none',

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250
});

function DataFields({ dispatch }) {
  const [state, setState] = useState([[], dataFields]);

  const handleAddBasic = () => {
    const temp = dataFields.filter((y) => !!BASIC_FIELD_LIST[y.name]);
    setState([[...state[0], ...temp], dataFields.filter((y) => !BASIC_FIELD_LIST[y.name])]);
  };

  const handleAddAll = () => {
    setState([[...dataFields], []]);
  };

  const handleRemoveAll = () => {
    setState([[], [...dataFields]]);
  };

  useEffect(() => {
    dispatch({
      type: REDUCER_STATE.SET_HEADERS,
      payload: state[0].map((m) => m.name) ?? []
    });
  }, [state]);

  const getUserPrefFields = async () => {
    try {
      const resp = await msaterDataApi.getUserPreferenceFields();
      if (resp?.data?.length > 0) {
        setState([[], ...dataFields]);
      }
      const temp = dataFields.filter((y) => resp.data.findIndex((x) => x === y.name) > -1);
      setState([
        [...state[0], ...temp],
        dataFields.filter((y) => resp.data.findIndex((x) => x === y.name) < 0)
      ]);
    } catch (error) {
      console.log('error', error);
    }
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState.filter((group) => group.length));
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <div style={{ textAlign: 'center', width: '50%' }}>Re-arrange Data-fields</div>
        <div style={{ textAlign: 'center', width: '50%' }}>Available Data-fields</div>
      </div>
      <div className="container" style={{ display: 'flex' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((el, ind) => (
            <Droppable className="column" key={ind} droppableId={`${ind}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}>
                  {el.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around'
                            }}>
                            {item.name}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <div style={{ textAlign: 'left', width: '50%', paddingLeft: 10 }}>
          <button onClick={handleRemoveAll}>Remove All</button>
        </div>
        <div style={{ textAlign: 'left', width: '50%', paddingLeft: 10 }}>
          <button onClick={handleAddAll} style={{ marginRight: 10 }}>
            Add All
          </button>
          <button onClick={handleAddBasic} style={{ marginRight: 10 }}>
            Basic Set
          </button>
          <button onClick={getUserPrefFields}>Use Custom Column Order</button>
        </div>
      </div>
    </div>
  );
}

export default DataFields;

import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import masterDataApi from '../../../../services/masterDataApi';
import { REDUCER_STATE } from '../../reducer';
import './style.css';

const ListBuilderStep3 = (props) => {
  const [isStateFilterExpanded, setIsStateFilterExpanded] = useState(false);
  const [isCountryFilterExpanded, setIsCountryFilterExpanded] = useState(false);
  const [isSizeFilterExpanded, setIsSizeFilterExpanded] = useState(false);
  const [isDistributorFilterExpanded, setIsDistributorFilterExpanded] = useState(false);
  const [selectedStateFilters, setSelectedStateFilters] = useState(null);
  const [selectedCountryFilters, setSelectedCountryFilters] = useState(null);
  const [stateFilterList, setStateFilterList] = useState(null);
  const [countryFilterList, setCountryFilterList] = useState(null);
  const [isFilterBySize, setIsFilterBySize] = useState(false);
  const [isDistributor, setIsDistributor] = useState(false);
  const [filterSize, setFilterSize] = useState({ min: '', max: '' });
  const { dispatch } = props;
  useEffect(() => {
    dispatch({
      type: REDUCER_STATE.SET_STATE_FILTER,
      payload: selectedStateFilters ? selectedStateFilters.map((m) => m?.label) : []
    });
    dispatch({ type: REDUCER_STATE.SET_LIST_SPREAD, payload: 0 });
    dispatch({
      type: REDUCER_STATE.SET_COUNTRY_FILTER,
      payload: selectedCountryFilters
        ? selectedCountryFilters.map((m) => m?.label?.split(' - ')[1])
        : []
    });
    // dispatch({type:REDUCER_STATE,payload:isFilterBySize})
    dispatch({ type: REDUCER_STATE.SET_DISTRIBUTOR_NAME, payload: 'test' });
    dispatch({ type: REDUCER_STATE.SET_MAX_LIST_LENGTH, payload: +filterSize.max });
    dispatch({ type: REDUCER_STATE.SET_MIN_LIST_LENGTH, payload: +filterSize.min });
  }, [
    selectedStateFilters,
    selectedStateFilters,
    selectedCountryFilters,
    isFilterBySize,
    isDistributor,
    filterSize
  ]);

  const getStateFilters = async (abortController) => {
    try {
      const response = await masterDataApi.getStateFilterList(abortController);
      if (response) {
        setStateFilterList(response?.data.map((m, i) => ({ value: i, label: m.name })));
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  const getCountryFilters = async (abortController) => {
    try {
      const response = await masterDataApi.getCountryFilterList(abortController);
      if (response) {
        setCountryFilterList(
          response?.data.map((m) => ({ value: m.id, label: `${m.code} - ${m.name}` }))
        );
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getStateFilters(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    getCountryFilters(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  const handleClearSelection = (name) => {
    if (name === 'state') {
      setSelectedStateFilters(null);
    }
    if (name === 'country') {
      setSelectedCountryFilters(null);
    }
  };

  const addAllStateHandler = () => {
    setSelectedStateFilters(stateFilterList);
  };

  const addAllCountryHandler = () => {
    setSelectedCountryFilters(countryFilterList);
  };

  return (
    <div className="step-3-wrapper">
      <div className="list-builder-header">Step 3 - Add Filters</div>
      <div className="state-filter-wrapper">
        <div>
          <span className="sic-label">State Filter</span>
          <span
            className="toggle-label"
            onClick={() => setIsStateFilterExpanded(!isStateFilterExpanded)}>
            {isStateFilterExpanded ? '(Hide)' : '(Show)'}
          </span>
          <a
            className="data_help data_help_toggle"
            title="Click on List to add them to your list builder.  States will be used to filter results when the List Builder is run."
            disabled="disabled"
            style={{ opacity: 0.5 }}></a>
        </div>
        {isStateFilterExpanded && (
          <div className="sic-select-wrapper">
            <Select
              isMulti
              className="sic-select"
              options={stateFilterList}
              onChange={setSelectedStateFilters}
              value={selectedStateFilters}
              isClearable={false}
            />
            <button className="btn-clear-sic" onClick={() => handleClearSelection('state')}>
              Clear States
            </button>
            <button className="btn-clear-sic" onClick={addAllStateHandler}>
              Add All
            </button>
          </div>
        )}
      </div>
      <div className="naics-code-wrapper">
        <div className="naics-label">
          Country Filter
          <span
            className="toggle-label"
            onClick={() => setIsCountryFilterExpanded(!isCountryFilterExpanded)}>
            ({isCountryFilterExpanded ? 'Hide' : 'Show'})
          </span>
          <a
            className="data_help data_help_toggle"
            title="Click on List to add them to your list builder.  Countries will be used to filter results when the List Builder is run."
            disabled="disabled"
            style={{ opacity: 0.5 }}></a>
        </div>
        {isCountryFilterExpanded && (
          <div className="naics-select-wrapper">
            <div className="checkbox-container">
              <Select
                isMulti
                className="naics-select"
                options={countryFilterList}
                onChange={setSelectedCountryFilters}
                value={selectedCountryFilters}
                isClearable={false}
              />
            </div>
            <button className="btn-clear-naics" onClick={() => handleClearSelection('country')}>
              Clear Countries
            </button>
            <button className="btn-clear-naics" onClick={addAllCountryHandler}>
              Add All
            </button>
          </div>
        )}
      </div>
      <div className="size-filter-wrapper">
        <table>
          <tbody>
            <tr>
              <td>
                <h2>Size Filter</h2>
              </td>
              <td>
                <a
                  className="toggle-label size_filter_hider"
                  onClick={() => setIsSizeFilterExpanded(!isSizeFilterExpanded)}
                  style={{ opacity: 1 }}>
                  ({isSizeFilterExpanded ? 'Hide' : 'Show'})
                </a>
              </td>
              <td>
                <a
                  className="data_help data_help_toggle"
                  title="Only locations in lists whose count falls within this filter will be included.  Use the min or max, or both."
                  disabled="disabled"
                  style={{ opacity: 0.5 }}></a>
              </td>
            </tr>
          </tbody>
        </table>
        {isSizeFilterExpanded && (
          <div className="size_filter">
            <p>
              <input
                id="list_size"
                name="list_size"
                type="checkbox"
                value="true"
                checked={isFilterBySize}
                onChange={() => setIsFilterBySize(!isFilterBySize)}
              />
              <label htmlFor="list_size" style={{ display: 'inline' }}>
                Filter by Size
              </label>
            </p>

            <p>
              <label htmlFor="min_list_length">Minimum Size</label>
              <input
                id="list_builder_min_list_length"
                name="list_builder_min_list_length"
                size="30"
                style={{ opacity: 0.5, width: 100 + 'px', marginLeft: 5 + 'px' }}
                type="text"
                disabled={!isFilterBySize}
                value={filterSize.min}
                onChange={(e) => setFilterSize({ max: filterSize.max, min: e.target.value })}
              />
            </p>
            <p>
              <label htmlFor="max_list_length">Maximum Size</label>
              <input
                id="list_builder_max_list_length"
                name="list_builder_max_list_length"
                size="30"
                style={{ opacity: 0.5, width: 100 + 'px', marginLeft: 5 + 'px' }}
                type="text"
                disabled={!isFilterBySize}
                value={filterSize.max}
                onChange={(e) => setFilterSize({ min: filterSize.min, max: e.target.value })}
              />
            </p>
          </div>
        )}
      </div>
      <div className="distribution-filter-wrapper">
        <table>
          <tbody>
            <tr>
              <td>
                <h2>Distributor Filter</h2>
              </td>
              <td>
                <a
                  className="toggle-label distributor_filter_hider"
                  onClick={() => setIsDistributorFilterExpanded(!isDistributorFilterExpanded)}
                  style={{ opacity: 1 }}>
                  ({isDistributorFilterExpanded ? 'Hide' : 'Show'})
                </a>
              </td>
              <td>
                <a
                  className="data_help data_help_toggle"
                  title="Excludes all distributor location. A distributor location is defined as a location where the company offers its products or services, but does not own the store and the store is not branded with that company's name. Examples include cell phone service providers, clothing brands, and more."
                  disabled="disabled"
                  style={{ opacity: 0.5 }}></a>
              </td>
            </tr>
          </tbody>
        </table>
        {isDistributorFilterExpanded && (
          <div className="distributor_filter">
            <span>
              <label htmlFor="distributor_name">Exclude Distributors</label>
              <input
                id="list_builder_distributor_name"
                name="list_builder_distributor_name"
                type="checkbox"
                style={{ verticalAlign: 'bottom', marginLeft: '10px' }}
                checked={isDistributor}
                onChange={() => setIsDistributor(!isDistributor)}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListBuilderStep3;

import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import { REDUCER_STATE } from '../../reducer';

const FREQUENCY_OPTIONS = [
  { value: 0, label: '' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' }
];

const HistoricalSnapshot = ({ dispatch }) => {
  const [useHistorical, setUseHistorical] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [snapshotParam, setSnapshotParam] = useState({
    frequencyNum: 0,
    duration: ''
  });
  const [snapshotList, setSnapshotList] = useState([]);
  const [isEditingTolerance, setIsEditingTolerance] = useState(false);
  const [tolerance, setTolerance] = useState({
    upto: '',
    period: '',
    beforeOrAfter: 'before'
  });

  useEffect(() => {
    dispatch({
      type: REDUCER_STATE.HLB_OBJECT,
      payload: { tolerance: tolerance, snapshotList: snapshotList }
    });
    dispatch({
      type: REDUCER_STATE.SET_IS_HISTORICAL,
      payload: !!useHistorical
    });
  }, [snapshotList, tolerance]);

  const handleUseHistoricalOptionChange = () => {
    setUseHistorical(!useHistorical);
  };

  const getSnapshotList = () => {
    const list = [];
    const { duration, frequencyNum } = snapshotParam;
    const date = new Date(startDate);
    if (frequencyNum?.value > 0) {
      for (let i = 0; i < frequencyNum?.value; i++) {
        const uniqueId = `${i}-${new Date().getMilliseconds().toString()}`;
        if (duration?.value === 'monthly') {
          date.setMonth(date.getMonth() - 1);
          list.push({
            id: uniqueId,
            date: date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear()
          });
        }
        if (duration.value === 'querterly') {
          date.setMonth(date.getMonth() - 3);
          list.push({
            id: uniqueId,
            date: date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear()
          });
        }
        if (duration.value === 'annual') {
          date.setFullYear(date.getFullYear() - 1);
          list.push({
            id: uniqueId,
            date: date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear()
          });
        }
      }
    }

    return list;
  };

  const handleAddSnapshot = () => {
    const _snapshotList = getSnapshotList();
    setSnapshotList(_snapshotList);
  };

  const removeAllSnapshot = () => {
    setSnapshotList([]);
  };

  const deleteSnapshot = (id) => {
    setSnapshotList(snapshotList.filter((m) => m.id !== id));
  };

  const toggleEditTolerence = () => {
    setIsEditingTolerance(!isEditingTolerance);
  };

  const editSnapshotListItem = (id, newValue) => {
    const temp = snapshotList.slice();
    const index = snapshotList.findIndex((m) => m.id === id);
    const objAtIndex = snapshotList[0];
    objAtIndex.date = newValue;
    temp.splice(index, 1, objAtIndex);
  };

  return (
    <div className="historical-section">
      <label htmlFor="chk-use-historical">
        <input onChange={handleUseHistoricalOptionChange} type="checkbox" id="chk-use-historical" />
        <span>Add Historical Snapshot</span>
      </label>
      {useHistorical && (
        <div className="historical-tool-wrapper">
          <div className="historical-snapshot-label">Historical Snapshots</div>
          <div>Location as of:</div>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />

          <div className="frequency-wrapper">
            <div className="frequency-label">Frequency</div>
            <div className="frequency-selector">
              <ReactSelect
                options={FREQUENCY_OPTIONS}
                onChange={(newVal) => setSnapshotParam({ ...snapshotParam, frequencyNum: newVal })}
                value={snapshotParam.frequencyNum}
                isClearable={false}
              />
              <ReactSelect
                options={[
                  { value: 'monthly', label: 'Monthly' },
                  { value: 'querterly', label: 'Querterly' },
                  { value: 'annual', label: 'Annual' }
                ]}
                onChange={(newVal) => setSnapshotParam({ ...snapshotParam, duration: newVal })}
                value={snapshotParam.duration}
                isClearable={false}
              />
              <button onClick={handleAddSnapshot}>Add snapshot</button>
            </div>
            <div className="snapshot-table">
              <div className="snapshot-table-row">
                <table>
                  <tbody>
                    {snapshotList.map((snapshot) => (
                      <tr key={snapshot.id}>
                        <td>
                          <DatePicker
                            selected={new Date(snapshot.date)}
                            onChange={(date) => editSnapshotListItem(snapshot.date, date)}
                          />
                        </td>
                        <td>
                          <span style={{ marginRight: 20 }}>Edit</span>
                        </td>
                        <td>
                          <span onClick={() => deleteSnapshot(snapshot.id)}>Delete</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div style={{ textAlign: 'right', marginTop: 10 }}>
                <button onClick={removeAllSnapshot}>Remove All</button>
              </div>
            </div>
            <div className="tolerance-wrapper">
              <p>Include closest 12 months before/after snapshot date:</p>
              <div className="edit-tolerance-wrapper">
                {!isEditingTolerance && (
                  <button onClick={toggleEditTolerence}>Edit Tolerance</button>
                )}
                {isEditingTolerance && (
                  <>
                    <button>Apply</button>
                    <button onClick={toggleEditTolerence}>Cancel</button>
                  </>
                )}
                {isEditingTolerance && (
                  <>
                    <ReactSelect
                      options={Array.from({ length: 12 }, (_, i) => ({
                        value: i + 1,
                        label: i + 1
                      }))}
                      onChange={(newVal) => setTolerance({ ...tolerance, upto: newVal?.value })}
                    />
                    <ReactSelect
                      options={[
                        { value: 'days', label: 'Days' },
                        { value: 'months', label: 'Months' },
                        { value: 'years', label: 'Years' }
                      ]}
                      onChange={(newVal) => setTolerance({ ...tolerance, period: newVal?.value })}
                    />
                    <ReactSelect
                      options={[
                        { value: 'before', label: 'Before' },
                        { value: 'after', label: 'After' }
                      ]}
                      onChange={(newVal) =>
                        setTolerance({ ...tolerance, beforeOrAfter: newVal.value })
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoricalSnapshot;

import React, { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import masterDataApi from '../../../../services/masterDataApi';
import { REDUCER_STATE } from '../../reducer';
import CategoriesSelector from './CategoriesSelector';
import './style.css';

const ListBuilderStep1 = (props) => {
  const [lists, setLists] = useState([]);
  const [sicCodeList, setSicCodeList] = useState([]);
  const [naicsCodeList, setNaicsCodeList] = useState([]);
  const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(false);
  const [isSicCodesExpanded, setIsSicCodesExpanded] = useState(false);
  const [isNaicsCodesExpanded, setIsNaicsCodesExpanded] = useState(false);
  const [isListSectionExpanded, setIsListSectionExpanded] = useState(false);
  const [selectedSicCodes, setSelectedSicCodes] = useState(null);
  const [selectedNaicsCodes, setSelectedNaicsCodes] = useState(null);
  const [selectedLists, setSelectedLists] = useState(null);
  const [onlyNaicsPrimary, setOnlyNaicsPrimary] = useState(false);
  const { dispatch } = props;

  useEffect(() => {
    dispatch({
      type: REDUCER_STATE.SET_NAICS_CODES_ATTRIBUTES,
      payload: selectedNaicsCodes ? selectedNaicsCodes?.map((m) => m.value) : []
    });
    dispatch({
      type: REDUCER_STATE.SET_FLAT_SIC_CODES,
      payload: selectedSicCodes?.map((m) => m.value)
    });
    dispatch({
      type: REDUCER_STATE.SET_FLAT_SIC_CODES,
      payload: selectedSicCodes?.map((m) => m.value)
    });
    dispatch({
      type: REDUCER_STATE.SET_LISTS_ATTRIBUTES,
      payload: selectedLists?.map((m) => m.value).join(',')
    });
    dispatch({ type: REDUCER_STATE.SET_ONLY_NAICS_PRIMARY, payload: onlyNaicsPrimary });
  }, [selectedSicCodes, selectedNaicsCodes, selectedLists, onlyNaicsPrimary]);

  const getSicCodes = async (abortController) => {
    try {
      const response = await masterDataApi.getSicCodeList(abortController);
      setSicCodeList(response?.data.map((m) => ({ value: m.id, label: `${m.code} ${m.name}` })));
    } catch (err) {
      console.log('Error', err);
    }
  };

  const getNaicsCodes = async (abortController) => {
    try {
      const response = await masterDataApi.getNaicsCodeList(abortController);
      setNaicsCodeList(
        response?.data.map((m) => ({ value: m.id, label: `${m.naicsCode} ${m.naicsTitle}` }))
      );
    } catch (err) {
      console.log('Error', err);
    }
  };

  const getLists = async (abortController) => {
    try {
      const response = await masterDataApi.getlists(abortController);
      setLists(response?.data?.map((m) => ({ value: m.id, label: m.shortName })));
    } catch (err) {
      console.log('Error', err);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getSicCodes(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    getNaicsCodes(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    getLists(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  const handleClearSelection = (name) => {
    if (name === 'sic') {
      setSelectedSicCodes(null);
    }
    if (name === 'naics') {
      setSelectedNaicsCodes(null);
    }
    if (name === 'lists') {
      setSelectedLists(null);
    }
  };

  return (
    <div className="step-1-wrapper">
      <div className="list-builder-header">Step 1 - Choose Lists</div>
      <div className="sic-code-wrapper">
        <div>
          <span className="sic-label">Categories</span>{' '}
          <span
            className="toggle-label"
            onClick={() => setIsCategoriesExpanded(!isCategoriesExpanded)}>
            {isCategoriesExpanded ? '(Hide)' : '(Show)'}
          </span>
          <span style={{ display: 'inline-block', verticalAlign: 'bottom' }}>
            <a
              className="data_help data_help_toggle"
              title="Drag Categories to the left side to include every list in that category in your list builder."
              disabled="disabled"
              style={{ opacity: 0.5 }}></a>
          </span>
        </div>
        <div style={{ display: isCategoriesExpanded ? 'block' : 'none' }}>
          <CategoriesSelector dispatch={dispatch} />
        </div>
      </div>
      <div className="sic-code-wrapper">
        <div>
          <span className="sic-label">SIC Codes</span>{' '}
          <span className="toggle-label" onClick={() => setIsSicCodesExpanded(!isSicCodesExpanded)}>
            {isSicCodesExpanded ? '(Hide)' : '(Show)'}
          </span>
          <span style={{ display: 'inline-block', verticalAlign: 'bottom' }}>
            <a
              className="data_help data_help_toggle"
              title="Click on individual SIC Codes to include only lists with those SIC values. Typing will automatically search, and clicking any 'x' will remove the selected filter."
              disabled="disabled"
              style={{ opacity: 0.5 }}></a>
          </span>
        </div>
        {isSicCodesExpanded && (
          <div className="sic-select-wrapper">
            <Select
              isMulti
              className="sic-select"
              options={sicCodeList}
              onChange={setSelectedSicCodes}
              value={selectedSicCodes}
              isClearable={false}
            />
            <button className="btn-clear-sic" onClick={() => handleClearSelection('sic')}>
              Clear Selected Sic Codes
            </button>
          </div>
        )}
      </div>
      <div className="naics-code-wrapper">
        <div className="naics-label">
          NAICS Codes{' '}
          <span
            className="toggle-label"
            onClick={() => setIsNaicsCodesExpanded(!isNaicsCodesExpanded)}>
            {isNaicsCodesExpanded ? 'Hide' : 'Show'}
          </span>
          <span style={{ display: 'inline-block', verticalAlign: 'bottom' }}>
            <a
              className="data_help data_help_toggle"
              title="Click on individual NAICS Codes to include only lists with those NAICS values. Typing will automatically search, and clicking any 'x' will remove the selected filter. Click “Only NAICS Primary” if the filter should only apply to the Primary NAICS codes, not Secondary."
              disabled="disabled"
              style={{ opacity: 0.5 }}></a>
          </span>
        </div>
        {isNaicsCodesExpanded && (
          <div className="naics-select-wrapper">
            <div className="checkbox-container">
              <Select
                isMulti
                className="naics-select"
                options={naicsCodeList}
                onChange={setSelectedNaicsCodes}
                value={selectedNaicsCodes}
                isClearable={false}
              />
              <label htmlFor="primary-naics-only">
                <span>Filter by Primary Naics Codes only</span>
                <input
                  id="primary-naics-only"
                  name="primary-naics-only"
                  type="checkbox"
                  checked={onlyNaicsPrimary}
                  onChange={() => setOnlyNaicsPrimary(!onlyNaicsPrimary)}
                />
              </label>
            </div>
            <button className="btn-clear-naics" onClick={() => handleClearSelection('naics')}>
              Clear Selected Naics Codes
            </button>
          </div>
        )}
      </div>
      <div className="naics-code-wrapper">
        <div className="naics-label">
          Lists
          <span
            className="toggle-label"
            onClick={() => setIsListSectionExpanded(!isListSectionExpanded)}>
            {isListSectionExpanded ? 'Hide' : 'Show'}
          </span>
          <span style={{ display: 'inline-block', verticalAlign: 'bottom' }}>
            <a
              className="data_help data_help_toggle"
              title="Click on lists to add them to your list builder.  Search in the field for more precision. All selected lists will be displayed in the 'Chosen Lists:' area.  Click the x next to a list to remove it."
              disabled="disabled"
              style={{ opacity: 0.5 }}></a>
          </span>
        </div>
        {isListSectionExpanded && (
          <div className="naics-select-wrapper">
            <Select
              isMulti
              className="naics-select"
              options={lists ?? []}
              onChange={setSelectedLists}
              value={selectedLists}
              isClearable={false}
            />
            <button className="btn-clear-naics" onClick={() => handleClearSelection('lists')}>
              Clear selected lists
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListBuilderStep1;

import React, { useEffect, useReducer } from 'react';
import { useState } from 'react';
import { REDUCER_STATE, listBuilderReducer, INIT_OBJ } from '../../reducer';
import './style.css';

const ListMetadata = (props) => {
  const { dispatch } = props;
  const [includeList, setIncludeList] = useState(0);
  const [downloadType, setDownloadType] = useState(0);
  const [splitFile, setSplitFile] = useState(false);
  const [lastUpdatedOnly, setLastUpdatedOnly] = useState(false);
  const [state] = useReducer(listBuilderReducer, INIT_OBJ);
  console.log('state ', state);

  useEffect(() => {
    dispatch({ type: REDUCER_STATE.SET_LAST_UPDATED_ONLY, payload: lastUpdatedOnly });
    dispatch({ type: REDUCER_STATE.SET_FILE_COMPOSITION, payload: +downloadType });
  }, [lastUpdatedOnly, downloadType]);
  const is_historical = true;

  return (
    <div id="single_col">
      <div className="section">
        <h4>Include these Lists...</h4>

        <div className="section-controls">
          <div>
            <label htmlFor="list_builder_list_spread_0">
              <input
                checked={includeList === 0}
                id="list_builder_list_spread_0"
                name="list_builder_all_list"
                type="radio"
                onChange={() => {
                  setIncludeList(0);
                  dispatch({ type: REDUCER_STATE.SET_LIST_SPREAD, payload: 0 });
                }}
                value="0"
              />
              <p>
                <strong>All Lists</strong>
                <br />
                Include all 9,440 Lists into this List Builder.
              </p>
            </label>
          </div>

          <div>
            <label htmlFor="list_builder_list_spread_1">
              <input
                checked={includeList === 1}
                id="list_builder_list_spread_1"
                name="list_builder_specified"
                type="radio"
                onChange={() => setIncludeList(1)}
                value="1"
              />
              <p>
                <strong>The Lists I specify, and their Sublists</strong>
                <br />
                Include the Lists I specify below, and their Sublists, into this List Builder.
                <br />
                <em>* Please note that you must specify at least one List.</em>
              </p>
            </label>
          </div>

          <div>
            <label htmlFor="list_builder_list_spread_2">
              <input
                checked={includeList === 2}
                id="list_builder_list_spread_2"
                name="list_builder_single"
                type="radio"
                value="2"
                onChange={() => setIncludeList(2)}
              />
              <p>
                <strong>Only the Lists I specify</strong>
                <br />
                Include only the Lists that match a selected category, SIC, or that I explicitly
                specify.
                <br />
                <em>* Please note that you must specify at least one List.</em>
              </p>
            </label>
          </div>
        </div>
      </div>
      <div className="section">
        <h4>Download this List Builder as...</h4>

        <div className="section-controls">
          <div>
            <label htmlFor="list_builder_file_composition_0">
              <input
                checked={downloadType === 0}
                className="floatleft"
                id="list_builder_file_composition_0"
                name="list_builder_compositon_0"
                type="radio"
                value="0"
                onChange={() => setDownloadType(0)}
              />
              <p>
                <strong>
                  {is_historical
                    ? ' A single file for all Lists and Historical Snapshots'
                    : ' A single file for all Lists'}
                </strong>
                <br />
                {is_historical
                  ? 'All lists ans Historical snapshots will be included in a single file'
                  : ' A single file for all Lists'}
              </p>
            </label>
          </div>

          <div>
            <label htmlFor="list_builder_file_composition_1">
              <input
                checked={downloadType === 1}
                className="floatleft"
                id="list_builder_file_composition_1"
                name="list_builder_compositon_1"
                type="radio"
                value="1"
                onChange={() => setDownloadType(1)}
              />
              <p>
                <strong>
                  {is_historical
                    ? 'Multiple files, grouped by Parent Lists and Sublists with all Historical Snapshots'
                    : 'Multiple files, grouped by Parent Lists and Sublists'}
                </strong>
                <br />
                {is_historical
                  ? 'Each Parent and Sub-Lists with all of theirhistorical snapshots will appear in their own file.'
                  : 'Parent Lists and their Sublists will appear in their own file.'}
              </p>
            </label>
          </div>

          <div>
            <label htmlFor="list_builder_file_composition_2">
              <input
                checked={downloadType === 2}
                id="list_builder_file_composition_2"
                name="list_builder_compositon_2"
                type="radio"
                value="2"
                onChange={() => setDownloadType(2)}
              />
              <p>
                <strong>
                  {is_historical
                    ? 'Multiple files, one File per List with all historical snapshots'
                    : 'Multiple files, one file per List'}
                </strong>
                <br />
                {is_historical
                  ? 'Every list with all of their historical snapshots will appear in their own file'
                  : 'Every List will appear in a separate file.'}
              </p>
            </label>
          </div>
          <div>
            <label htmlFor="list_builder_file_composition_3">
              <input
                checked={downloadType === 3}
                id="list_builder_file_composition_3"
                name="list_builder_compositon_3"
                type="radio"
                value="2"
                onChange={() => setDownloadType(3)}
              />
              <p>
                <strong>Multiple Files, One File per Snapshot with all Lists</strong>
                <br />
                Each Snapshot will appear in a separate file
              </p>
            </label>
          </div>
          <div>
            <label htmlFor="list_builder_file_composition_4">
              <input
                checked={downloadType === 4}
                id="list_builder_file_composition_4"
                name="list_builder_compositon_4"
                type="radio"
                value="2"
                onChange={() => setDownloadType(4)}
              />
              <p>
                <strong>Multiple File, One File per Snapshot Per List</strong>
                <br />
                Each snapshot per list wll appear in their own separate file
              </p>
            </label>
          </div>
        </div>
      </div>

      <div className="section">
        <h4>Split up very large files</h4>

        <div className="section-controls">
          <div>
            <label htmlFor="list_builder_split_file">
              <input
                checked={splitFile}
                id="list_builder_split_file"
                name="list_builder_split_file"
                type="checkbox"
                onChange={() => setSplitFile(!splitFile)}
              />
              <p>
                <strong>
                  Split up very large files into small files, each containing 1 million rows
                </strong>
              </p>
            </label>
          </div>
        </div>
      </div>
      <div className="section">
        <h4>Only include updated Lists</h4>

        <div className="section-controls">
          <div>
            <label htmlFor="list_builder_last_updated_only">
              <input
                checked={lastUpdatedOnly}
                id="list_builder_last_updated_only"
                name="list_builder_last_updated_only"
                type="checkbox"
                value="1"
                onChange={() => setLastUpdatedOnly(!lastUpdatedOnly)}
              />
              <p>
                <strong>
                  Only include Lists that have been updated since this List Builder was last run
                </strong>
              </p>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListMetadata;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import MainLayout from '../../components/layout/MainLayout';
import UserControlButtons from '../../components/shared/UserControls';
import userService from '../../services/user';
import listBuilderService from '../../services/listBuilder';
import moment from 'moment';

import DataTable from 'react-data-table-component';

import './style.css';
// import DataTableWithPercent from '../../components/datatable-with-percent';

const ListBuilders = () => {
  const [userFilters] = useState([]);
  const [selectedUserFilters, setSelectedUserFilters] = useState([]);
  const [listBuilder, setListBuilder] = useState([]);
  const [filteredListBuilder, setFilteredListBuilder] = useState([]);
  const [filterText, setFilterText] = React.useState('');

  const navigate = new useNavigate();

  const columns = [
    { selector: (row) => row.name, name: 'Name', sortable: true },
    { selector: (row) => row.status, name: 'Status', sortable: true },
    {
      name: 'Last Run',
      cell: (params) => {
        if (params?.lastRanAt) {
          return moment(params?.last_ran_at).format('YYYY-MM-DD');
        }
        return '';
      }
    },
    {
      name: 'Run',
      cell: (params) => {
        if (params.lastRanAt) {
          return (
            <>
              <button className="buttonToLink" onClick={() => runHandler(params.id)}>
                Rerun
              </button>
            </>
          );
        }
        return (
          <>
            <button className="buttonToLink" onClick={() => runHandler(params.id)}>
              Run
            </button>
          </>
        );
      }
    },
    {
      name: 'Download',
      cell: (params) => {
        if (params.lastRanAt) {
          return (
            <>
              <button
                className="buttonToLink"
                onClick={async () => {
                  let url = await downloadHandler(params.id);
                  console.log('url is ', url);
                  downloadFileUsingUrl(url.data);
                }}>
                Download
              </button>
            </>
          );
        }
        return '';
      }
    },
    {
      name: 'Edit',
      cell: (params) => {
        return (
          <>
            <button
              className="buttonToLink"
              onClick={() => navigate(`/list_builders/edit/${params.id}`)}>
              Edit
            </button>
          </>
        );
      }
    },
    {
      name: 'Delete',
      cell: (params) => {
        return (
          <>
            <button
              className="buttonToLink"
              onClick={async () => {
                await deleteListHandler(params.id);
              }}>
              Delete
            </button>
          </>
        );
      }
    },
    {
      name: 'Duplicate',
      cell: (params) => {
        return (
          <>
            <button className="buttonToLink" onClick={() => duplicateHandler(params.id)}>
              Duplicate
            </button>
          </>
        );
      }
    }
  ];

  const downloadFileUsingUrl = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `FileName.zip`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  const getUserListHandler = async () => {
    try {
      const response = await userService.getUserList();
      console.log('reponse from user service', response);
      setListBuilder(response?.data.map((m) => ({ value: m.id, label: `${m.name}` })));
    } catch (err) {
      console.log('Error', err);
    }
  };

  const getListBuilderListHandler = async () => {
    try {
      const response = await listBuilderService.getListBuilderList();
      console.log('reponse from list service', response);
      setListBuilder(response?.data);
      setFilteredListBuilder(response?.data);
      console.log('kst', filterText);
    } catch (err) {
      console.log('Error', err);
    }
  };

  const runHandler = async (listId) => {
    try {
      console.log('found list id ', listId);
      const response = await listBuilderService.run(listId);
      console.log('reponse from list service', response);
      return response;
    } catch (err) {
      console.log('Error', err);
    }
  };

  const deleteListHandler = async (listId) => {
    try {
      console.log('found list id ', listId);
      const response = await listBuilderService.deleteList(listId);
      console.log('reponse from list service', response);
      // listBuilder.splice(0,1);
      // setListBuilder(listBuilder)
      return response;
    } catch (err) {
      console.log('Error', err);
    }
  };

  const duplicateHandler = async (listId) => {
    try {
      console.log('found list id ', listId);
      const response = await listBuilderService.duplicate(listId);
      console.log('reponse from list service', response);
      return response;
    } catch (err) {
      console.log('Error', err);
    }
  };

  const downloadHandler = async (listId) => {
    // generate presigned url
    try {
      const userId = 70;
      console.log('found list id ', listId);
      const response = await listBuilderService.download(listId, userId);
      console.log('reponse from list service', response);
      return response;
    } catch (err) {
      console.log('Error', err);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getUserListHandler(abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    getListBuilderListHandler(abortController);
    console.log('list ', filterText);

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    setFilteredListBuilder(listBuilder.filter((e) => e?.name.includes(filterText)));
  }, [filterText]);

  function onFilter(value) {
    setFilterText(value);
  }

  return (
    <MainLayout>
      <div className="user-pref-wrapper">
        <div className="inner-container">
          <div>Search section</div>
          <div className="nav-button-wrapper">
            <div className="nav-section list-builder" />
            <div className="nav-section data-browser" />
            <div className="nav-section user-pref" />
            <UserControlButtons />
          </div>
        </div>
      </div>

      <div className="list-builder-section headings">
        <div className="list-builder-header">List Builder (0)</div>
        <div
          className="list-builder-header buttonToLink"
          onClick={() => navigate(`/list_builders/new`)}>
          New List Builder
        </div>
        <div className="list-builder-header buttonToLink">Queue</div>
      </div>

      <div className="user-list wrapper">
        <label>{`Select another user's list`}</label>
        <Select
          className="user-select"
          options={userFilters}
          onChange={setSelectedUserFilters}
          value={selectedUserFilters}
          isClearable={false}
        />
      </div>

      <div className="wrapper">
        <div className="grid-section">
          <div className="search">
            <input
              className="search-input"
              id="search"
              type="text"
              placeholder="Filter By Name"
              aria-label="Search Input"
              value={filterText}
              onChange={(e) => onFilter(e.target.value)}
            />
          </div>
          <DataTable columns={columns} data={filteredListBuilder} pagination />
          {/* <DataTableWithPercent /> */}
        </div>
      </div>
    </MainLayout>
  );
};

export default ListBuilders;

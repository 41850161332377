import axiosInstance from './axiosInstance';

const getListBuilderList = async () => {
  try {
    const response = await axiosInstance.get(`listbuilder?userId=70`);
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const run = async (listId) => {
  try {
    console.log('list id ', listId);
    const response = await axiosInstance.put(`listbuilder/run?id=${listId}`);
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const deleteList = async (listId) => {
  try {
    console.log('list id ', listId);
    const response = await axiosInstance.delete(`listbuilder/${listId}`);
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const duplicate = async (listId) => {
  try {
    console.log('list id ', listId);
    const response = await axiosInstance.post(`listbuilder/${listId}`);
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const download = async (listId, userId) => {
  try {
    listId = 11; //need to be deleted
    // eslint-disable-next-line no-unused-vars
    userId = 72; //need to be deleted
    // const response = await axiosInstance.get(`listbuilder/${listId}/exportUrl?userId=${userId}`);
    // return response.data;
    return {
      message: '',
      data: 'https://aggdata-dev.s3.us-east-1.amazonaws.com/test_simple_list_12507.zip?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHIaCmFwLXNvdXRoLTEiRzBFAiEAqMLJhqo1B1kQkD%2FFRL35Ecq0kgXS3KWXEYlYJieITvECIActeZtvYrL7PY2Q1Ici3gwi3VAwwTVMZBWkT%2BSImNpKKokDCBsQAhoMNDM2NzQ4NjA5Mzg3IgyH9QcKSfFTP%2BJIY4Aq5gIqj%2B9y473Hc0lnUbFEsUK%2B2pQpJlqKMWZfq04VG129ySUszopAg7PgYx8fX280nfcMDjqyRLuvtXM9dZKA1So%2BxEOukSaumiGCvNY%2BjmhBtEKVimSkLPCjOY8rEr3sL1mQFN8rmwkYhPs%2Fpwlu4j5LgpSjWbA525QYq%2B5kaYL9I2Ch%2BZ%2F%2F%2B6tK9ecZOJvdqVprkwpApKfDCYedxzusM8tntxpQEzdw8XEZMCsPG7mG1XwZavt4htTbSCjoVDnD%2F9VTYn8KEOfQpd3gDgngZGcOzc0nfUQucLDW87Y9FYVAP%2BgBMVPMffilgtR5QRxYquNr5ubXekyqPE7%2BLjux7coIrqh4FUtWDqs1ehfTpPWljzaSNFDcYieeTz7B80GT5o8K9tV%2F791RFzqfmTbT3asrL9qmQur873oC2zWtNL84xihApYMw638PUrBOtxl3obx38bFPxd4D%2BW3U4ZMNaFgBFax8PsM6MPH5458GOrMCvg7HQFgtnl7npbNPsuE2UyQSG7N09zwPJRTQ4vx4H9tQIKAokxkfUI%2BJ5VLKQL9TCDjsRDN5JgqEc0hRme7ibQnvnBlrDdKBL3zi8ULXZ5yH0SJmconDX3gGrV3ZoYaDvHPXTNh1hb3Rlp9NMUkONwg4s3B3SkQyJnWEUoojmzr3QR9qOSfpWL2eeJ%2Bf0ll3yw%2FSZ2fOH5MYzjSv%2Buf5SpJ3idZsJqPGk%2B75rfAYuMedmeYDzP6h63AJQqWDCtNBEbrGdTvx02udq8IMmg0VW1jP2NCskKLY5TueprNa1uzbC7hpptXpXKB0HPGrPFQqJ%2F1Xm4j5%2BsIvKeBPHLUMjaIGcRe9EVmTXgB3SeX24bxQ%2FRsVbbsWHu2VUjHOTZwdrQXQv9tqnGiCrr%2Fs91eG%2BZq5ow%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230224T200414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAWLMB645VQQXBLTRP%2F20230224%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=7d30151669b212cead0f8f2121e10004314d54648e53fc4cb33792bdfe8bb651'
    };
  } catch (e) {
    console.log('Error', e);
  }
};

const listBuilderService = {
  getListBuilderList,
  run,
  deleteList,
  duplicate,
  download
};

export default listBuilderService;

export const INIT_OBJ = {
  name: '',
  list_spread: '',
  file_composition: 1,
  split_file: false,
  last_updated_only: false,
  flat_tags: [],
  flat_sic_codes: [],
  naics_codes_attributes: [],
  only_naics_primary: false,
  lists_attributes: '',
  use_tracked_lists: false,
  original_fields: '0',
  headers: [],
  state_filter: [],
  country_filter: [],
  min_list_length: '',
  max_list_length: '',
  distributor_name: '',
  user_id: '',
  hlb_request_object: '',
  is_historical: false
};

export const REDUCER_STATE = Object.freeze({
  SET_NAME: 'SET_NAME',
  SET_LIST_SPREAD: 'SET_LIST_SPREAD',
  SET_FILE_COMPOSITION: 'SET_FILE_COMPOSITION',
  SET_SPLIT_FILE: 'SET_SPLIT_FILE',
  SET_LAST_UPDATED_ONLY: 'SET_LAST_UPDATED_ONLY',
  SET_FLAT_TAGS: 'SET_FLAT_TAGS',
  SET_FLAT_SIC_CODES: 'SET_FLAT_SIC_CODES',
  SET_NAICS_CODES_ATTRIBUTES: 'SET_NAICS_CODES_ATTRIBUTES',
  SET_ONLY_NAICS_PRIMARY: 'SET_ONLY_NAICS_PRIMARY',
  SET_LISTS_ATTRIBUTES: 'SET_LISTS_ATTRIBUTES',
  SET_USE_TRACKED_LISTS: 'SET_USE_TRACKED_LISTS',
  SET_ORIGINAL_FIELDS: 'SET_ORIGINAL_FIELDS',
  SET_HEADERS: 'SET_HEADERS',
  SET_STATE_FILTER: 'SET_STATE_FILTER',
  SET_COUNTRY_FILTER: 'SET_COUNTRY_FILTER',
  SET_MIN_LIST_LENGTH: 'SET_MIN_LIST_LENGTH',
  SET_MAX_LIST_LENGTH: 'SET_MAX_LIST_LENGTH',
  SET_DISTRIBUTOR_NAME: 'SET_DISTRIBUTOR_NAME',
  SET_USER_ID: 'SET_USER_ID',
  INIT_OBJ: 'INIT_OBJ',
  SET_HLB_OBJECT: 'SET_HLB_OBJECT',
  SET_IS_HISTORICAL: 'SET_IS_HISTORICAL'
});

export const listBuilderReducer = (state, action) => {
  switch (action.type) {
    case REDUCER_STATE.SET_NAME: {
      return { ...state, name: action.payload };
    }
    case REDUCER_STATE.SET_LIST_SPREAD: {
      return { ...state, list_spread: action.payload };
    }
    case REDUCER_STATE.SET_COUNTRY_FILTER: {
      return { ...state, country_filter: action.payload };
    }
    case REDUCER_STATE.SET_DISTRIBUTOR_NAME: {
      return { ...state, distributor_name: action.payload };
    }
    case REDUCER_STATE.SET_FILE_COMPOSITION: {
      return { ...state, file_composition: action.payload };
    }
    case REDUCER_STATE.SET_FLAT_SIC_CODES: {
      return { ...state, flat_sic_codes: action.payload };
    }
    case REDUCER_STATE.SET_FLAT_TAGS: {
      return { ...state, flat_tags: action.payload };
    }
    case REDUCER_STATE.SET_HEADERS: {
      return { ...state, headers: action.payload };
    }
    case REDUCER_STATE.SET_LAST_UPDATED_ONLY: {
      return { ...state, last_updated_only: action.payload };
    }
    case REDUCER_STATE.SET_LISTS_ATTRIBUTES: {
      return { ...state, lists_attributes: action.payload };
    }
    case REDUCER_STATE.SET_MAX_LIST_LENGTH: {
      return { ...state, max_list_length: action.payload };
    }
    case REDUCER_STATE.SET_MIN_LIST_LENGTH: {
      return { ...state, min_list_length: action.payload };
    }
    case REDUCER_STATE.SET_NAICS_CODES_ATTRIBUTES: {
      return { ...state, naics_codes_attributes: action.payload };
    }
    case REDUCER_STATE.SET_ONLY_NAICS_PRIMARY: {
      return { ...state, only_naics_primary: action.payload };
    }
    case REDUCER_STATE.SET_ORIGINAL_FIELDS: {
      return { ...state, original_fields: action.payload };
    }
    case REDUCER_STATE.SET_SPLIT_FILE: {
      return { ...state, split_file: action.payload };
    }
    case REDUCER_STATE.SET_STATE_FILTER: {
      return { ...state, state_filter: action.payload };
    }
    case REDUCER_STATE.SET_USER_ID: {
      return { ...state, user_id: action.payload };
    }
    case REDUCER_STATE.SET_USE_TRACKED_LISTS: {
      return { ...state, use_tracked_lists: action.payload };
    }
    case REDUCER_STATE.HLB_OBJECT: {
      return { ...state, hlb_request_object: action.payload };
    }
    case REDUCER_STATE.SET_IS_HISTORICAL: {
      return { ...state, is_historical: action.payload };
    }
    case REDUCER_STATE.INIT_OBJ: {
      return {
        name: '',
        list_spread: '',
        file_composition: '',
        split_file: false,
        last_updated_only: false,
        flat_tags: [],
        flat_sic_codes: [],
        naics_codes_attributes: [],
        only_naics_primary: false,
        lists_attributes: '',
        use_tracked_lists: false,
        original_fields: '0',
        headers: [],
        state_filter: [],
        country_filter: [],
        min_list_length: '',
        max_list_length: '',
        distributor_name: '',
        hlb_request_object: {},
        is_historical: false,
        user_id: ''
      };
    }
  }
};

import React from 'react';

import Footer from '../footer/footer';
import Header from '../header/header';

const MainLayout = ({ children }) => {
  return (
    <div className="app" id="site_wrapper">
      <div className="app" id="site_container">
        <Header />
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;

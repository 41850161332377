import React, { useState } from 'react';
import { REDUCER_STATE } from '../../reducer';
import DataFields from './DataFields';
import './style.css';

const ListBuilderStep2 = (props) => {
  const [useOriginalFields, setUseOriginalFields] = useState(false);
  const { dispatch } = props;

  const handleUseOriginalFieldsChange = (evt) => {
    const { checked } = evt.target;
    dispatch({ type: REDUCER_STATE.SET_ORIGINAL_FIELDS, payload: checked ? '1' : 0 });
    setUseOriginalFields(checked);
  };

  return (
    <div className="step-2-wrapper">
      <div className="list-builder-header">Step 2 - Select and Arrange Data-fields</div>
      <div style={{ marginTop: 15 }}>
        <label htmlFor="chk-use-field-order">
          <input
            id="chk-use-field-order"
            name="chk-use-field-order"
            type="checkbox"
            checked={useOriginalFields}
            onChange={handleUseOriginalFieldsChange}
          />
          <span>Use field order from each original List</span>
        </label>
      </div>
      {!useOriginalFields && <DataFields dispatch={dispatch}></DataFields>}
      <div style={{ border: '2px solid #D0E6EE', padding: 5, margin: 10, fontSize: 14 }}>
        <strong>Data dictionary</strong>
        <p>
          Click{' '}
          <a
            target="_blank"
            href="https://s3.amazonaws.com/aggdata_docs/AggData+Dictionary.pdf"
            rel="noreferrer">
            here
          </a>{' '}
          to download the Data Dictionary, which has detailed explanations of each field.
        </p>
      </div>
    </div>
  );
};

export default ListBuilderStep2;

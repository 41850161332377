import React, { useEffect, useReducer, useState } from 'react';

import MainLayout from '../../components/layout/MainLayout';
import ListMetadata from './components/list-builder-metadata/ListMetadata';
import ListBuilderStep1 from './components/list-builder-step1/ListBuilderStep1';
import ListBuilderStep2 from './components/list-builder-step2/ListBuilderStep2';
import UserControlButtons from '../../components/shared/UserControls';

import './style.css';
import ListBuilderStep3 from './components/list-builder-step3/ListBuilderStep3';
import HistoricalSnapshot from './components/historical-snapshot/HistoricalSnapshot';

import Search from '../../components/search/search';
import { listBuilderReducer, INIT_OBJ, REDUCER_STATE } from './reducer';
import listBuilderApi from '../../services/listBuilderApi';

const CreateListBuilder = () => {
  const [state, dispatch] = useReducer(listBuilderReducer, INIT_OBJ);
  const [lbName, setLbName] = useState('');

  useEffect(() => {
    dispatch({ type: REDUCER_STATE.SET_NAME, payload: lbName });
  }, [lbName]);

  const saveListBuilder = async (run) => {
    if (!state.is_historical) {
      delete state.hlb_request_object;
      delete state.is_historical;
    }
    state.user_id = 70;
    if (run) {
      state.is_run = true;
    }
    try {
      const resp = await listBuilderApi.saveListBuilder(state);
      if (resp) {
        console.log('success', resp);
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  return (
    <MainLayout>
      <div className="user-pref-wrapper">
        <div className="inner-container">
          <div>
            <Search />
          </div>
          <div className="nav-button-wrapper">
            <div className="nav-section list-builder" />
            <div className="nav-section data-browser" />
            <div className="nav-section user-pref" />
            <UserControlButtons />
          </div>
        </div>
      </div>
      <div className="list-builder-section">
        <div className="list-builder-header">New List Builder</div>
        <div className="elem-set">
          <h4>Name this List Builder</h4>
          <p>
            <input
              id="list_builder_name"
              size="30"
              type="text"
              value={lbName}
              onChange={(evt) => setLbName(evt.target.value)}
            />
          </p>
        </div>
        <HistoricalSnapshot dispatch={dispatch} />
        <ListMetadata dispatch={dispatch} />
        <ListBuilderStep1 dispatch={dispatch} />
        <ListBuilderStep2 dispatch={dispatch} />
        <ListBuilderStep3 dispatch={dispatch} />
        <div style={{ paddingBottom: 20 }}>
          <button type="button" onClick={saveListBuilder}>
            Save List Builder
          </button>
          <button
            type="button"
            style={{ marginLeft: 5 }}
            onClick={() => {
              saveListBuilder(true);
            }}>
            Save and Run List Builder
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default CreateListBuilder;

import React from 'react';

const Header = () => (
  <header className="header-container">
    <img
      src="https://qa.aggdata.com/assets/site_logo-19b9ac556ace21efcb26cc6092e58a25.png"
      height="50"
    />
  </header>
);

export default Header;

export const COLUMN_NAMES = {
  DO_IT: 'Do it',
  IN_PROGRESS: 'In Progress'
};

const { IN_PROGRESS } = COLUMN_NAMES;

export default [
  { id: 1, name: 'address', column: IN_PROGRESS },
  { id: 2, name: 'address_line_2', column: IN_PROGRESS },
  { id: 3, name: 'address_line_3', column: IN_PROGRESS },
  { id: 4, name: 'ali', column: IN_PROGRESS },
  { id: 5, name: 'category', column: IN_PROGRESS },
  { id: 6, name: 'check_in', column: IN_PROGRESS },
  { id: 7, name: 'check_out', column: IN_PROGRESS },
  { id: 8, name: 'city', column: IN_PROGRESS },
  { id: 9, name: 'coming_soon', column: IN_PROGRESS },
  { id: 10, name: 'contact_name', column: IN_PROGRESS },
  { id: 11, name: 'country', column: IN_PROGRESS },
  { id: 12, name: 'county', column: IN_PROGRESS },
  { id: 13, name: 'distributor_name', column: IN_PROGRESS },
  { id: 14, name: 'drive_through', column: IN_PROGRESS },
  { id: 15, name: 'email_address', column: IN_PROGRESS },
  { id: 16, name: 'fax_number', column: IN_PROGRESS },
  { id: 17, name: 'gas', column: IN_PROGRESS },
  { id: 18, name: 'geo_accuracy', column: IN_PROGRESS },
  { id: 19, name: 'last_update', column: IN_PROGRESS },
  { id: 20, name: 'lattitude', column: IN_PROGRESS },
  { id: 21, name: 'list_id', column: IN_PROGRESS },
  { id: 22, name: 'list_name', column: IN_PROGRESS },
  { id: 23, name: 'longitude', column: IN_PROGRESS },
  { id: 24, name: 'manager', column: IN_PROGRESS },
  { id: 25, name: 'naics_code_primary', column: IN_PROGRESS },
  { id: 26, name: 'naics_code_secondary', column: IN_PROGRESS },
  { id: 27, name: 'open_date', column: IN_PROGRESS },
  { id: 28, name: 'other_fields', column: IN_PROGRESS },
  { id: 29, name: 'owner', column: IN_PROGRESS },
  { id: 30, name: 'parent_list_id', column: IN_PROGRESS },
  { id: 31, name: 'parent_list_name', column: IN_PROGRESS },
  { id: 32, name: 'pharmacy', column: IN_PROGRESS },
  { id: 33, name: 'pharmacy_fax_number', column: IN_PROGRESS },
  { id: 34, name: 'pharmacy_hours', column: IN_PROGRESS },
  { id: 35, name: 'pharmacy_phone_number', column: IN_PROGRESS },
  { id: 36, name: 'phone_number', column: IN_PROGRESS },
  { id: 37, name: 'products', column: IN_PROGRESS },
  { id: 38, name: 'services', column: IN_PROGRESS },
  { id: 39, name: 'sic_code', column: IN_PROGRESS },
  { id: 40, name: 'source_url', column: IN_PROGRESS },
  { id: 41, name: 'special_hours', column: IN_PROGRESS },
  { id: 42, name: 'special_hours_label', column: IN_PROGRESS },
  { id: 43, name: 'state', column: IN_PROGRESS },
  { id: 44, name: 'store_hours', column: IN_PROGRESS },
  { id: 45, name: 'store_name', column: IN_PROGRESS },
  { id: 46, name: 'store_number', column: IN_PROGRESS },
  { id: 47, name: 'store_type', column: IN_PROGRESS },
  { id: 48, name: 'storefront', column: IN_PROGRESS },
  { id: 49, name: 'tenant_list', column: IN_PROGRESS },
  { id: 50, name: 'unformatted_fax_number', column: IN_PROGRESS },
  { id: 51, name: 'unformatted_phone_number', column: IN_PROGRESS },
  { id: 52, name: 'website_address', column: IN_PROGRESS },
  { id: 53, name: 'zip_code', column: IN_PROGRESS }
];

export const BASIC_FIELD_LIST = {
  list_name: 'list_name',
  address: 'address',
  city: 'city',
  state: 'state',
  zip_code: 'zip_code',
  phone_number: 'phone_number',
  lattitude: 'lattitude',
  longitude: 'longitude'
};

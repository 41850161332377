import axiosInstance from './axiosInstance';

const saveListBuilder = async (payload) => {
  try {
    const response = await axiosInstance.post('/listbuilder', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const listBuilderApi = {
  saveListBuilder
};

export default listBuilderApi;

import React from 'react';

import './style.css';

const Footer = () => (
  <footer className="app-footer">
    <div className="footer-inner-container">
      <div>
        <div className="align-left">
          <a href="#">Privacy Policy</a>
          <span className="mx1 divider"></span>
          <a href="#">Site Map</a>
          <span className="mx1 divider"></span>
          <a href="#">Terms & Conditions</a>
        </div>
        <div>
          Copyright © 2005 - 2023 <a href="#">AggData</a>. All Rights Reserved
        </div>
      </div>
      <div className="link-wrapper">
        <a href="https://www.facebook.com/pages/AggDatacom/99221817174" className="lnk-facebook" />
        <a href="https://twitter.com/aggdata" className="lnk-twitter" />
        <a href="https://www.linkedin.com/company/aggdata-llc" className="lnk-linkedin" />
      </div>
    </div>
  </footer>
);

export default Footer;

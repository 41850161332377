import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import masterDataApi from '../../../../services/masterDataApi';
import { DndWrapper } from '../../../../components/category-dropdown/DndWrapper';
import './style.css';
import { REDUCER_STATE } from '../../reducer';

const COLUMN_NAMES = {
  DO_IT: 'Do it',
  IN_PROGRESS: 'In Progress'
};

const CategoriesSelector = (props) => {
  const { dispatch } = props;
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({ type: REDUCER_STATE.SET_FLAT_TAGS, payload: selectedItems?.map((m) => m.id) });
  }, [selectedItems]);

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = items[dragIndex];

    if (dragItem) {
      setItems((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  };

  const returnItemsForColumn = (columnName) => {
    return items
      .filter((item) => item.column === columnName && item.childs.length > 0)
      .map((item, index) => (
        <MovableParentItem
          key={item.id}
          name={item.name}
          currentColumnName={item.column}
          setItems={setItems}
          index={index}
          tags={item.childs}
          moveCardHandler={moveCardHandler}
          setSelectedItems={setSelectedItems}
        />
      ));
  };

  const getSelectedItems = () => {
    return selectedItems.map((item, index) => (
      <MovableItem
        key={item.id}
        name={item.name}
        currentColumnName={item.column}
        setItems={setItems}
        index={index}
        moveCardHandler={moveCardHandler}
        setSelectedItems={setSelectedItems}
      />
    ));
  };

  const handleAddAll = () => {
    const newSelectedtems = items.flatMap((x) => x.childs);
    newSelectedtems.forEach((y) => (y.column = DO_IT));
    setSelectedItems([...selectedItems, ...newSelectedtems]);
    setTimeout(() => {
      setItems(
        items.map((x) => {
          return { ...x, childs: [] };
        })
      );
    }, 1);
  };

  const handleRemoveAll = () => {
    const temp = [...items];
    selectedItems.forEach((x) => {
      const mainItem = temp.find((y) => y.id === x.parentCategoryId);
      if (mainItem) {
        mainItem.childs = [...mainItem.childs, { ...x, column: IN_PROGRESS }];
      }
    });
    setSelectedItems([]);
    setTimeout(() => {
      setItems(temp);
    }, 1);
  };

  const { DO_IT, IN_PROGRESS } = COLUMN_NAMES;

  const getCategories = useCallback(
    async (abortController) => {
      try {
        setIsLoading(true);

        const response = await masterDataApi.getCategories(abortController);
        if (response) {
          setItems(
            response?.data.map((m) => {
              return {
                id: m.id,
                name: m.name,
                column: IN_PROGRESS,
                childs: m.tags
                  .filter((c) => selectedItems.findIndex((y) => y.name === c.name) < 0)
                  .map((child) => {
                    return {
                      id: child.id,
                      name: child.name,
                      parentCategoryId: child.parentCategoryId,
                      column: IN_PROGRESS
                    };
                  })
              };
            })
          );
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 1);
      } catch (err) {
        setIsLoading(false);
        console.log('Error', err);
      }
    },
    [IN_PROGRESS]
  );

  useEffect(() => {
    const abortController = new AbortController();
    getCategories(abortController);

    return () => {
      abortController.abort();
    };
  }, [getCategories]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <div style={{ textAlign: 'center', width: '50%' }}>Re-arrange Data-fields</div>
        <div style={{ textAlign: 'center', width: '50%' }}>Available Data-fields</div>
      </div>
      <div
        style={{ display: isLoading ? 'none' : 'flex' }}
        className="container"
        id="categories-selector">
        <DndWrapper id={'categories-selector'}>
          <Column title={DO_IT} className="column do-it-column">
            {getSelectedItems()}
          </Column>
          <Column title={IN_PROGRESS} className="column in-progress-column auto-height-col">
            {returnItemsForColumn(IN_PROGRESS)}
          </Column>
        </DndWrapper>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <div style={{ textAlign: 'left', width: '50%', paddingLeft: 10 }}>
          <button onClick={handleRemoveAll}>Remove All</button>
        </div>
        <div style={{ textAlign: 'left', width: '50%', paddingLeft: 10 }}>
          <button onClick={handleAddAll} style={{ marginRight: 10 }}>
            Add All
          </button>
        </div>
      </div>
    </>
  );
};

const MovableItem = ({
  name,
  index,
  currentColumnName,
  moveCardHandler,
  setItems,
  setSelectedItems,
  parentCategoryId
}) => {
  const changeItemColumn = (currentItem, columnName) => {
    setItems((prevState) => {
      return prevState.map((e) => {
        return {
          ...e,
          childs: getChilds(e, currentItem, columnName)
        };
      });
    });
    setTimeout(() => {
      setSelectedItems((prevState) => {
        const { DO_IT } = COLUMN_NAMES;
        if (columnName === DO_IT) {
          return [...prevState, { ...currentItem, column: columnName }];
        } else {
          return prevState.filter((x) => x.name !== currentItem.name);
        }
      });
    }, 1);
  };

  const getChilds = (parentItem, currentItem, columnName) => {
    const { DO_IT } = COLUMN_NAMES;
    if (columnName === DO_IT) {
      return parentItem.childs.filter((x) => x.name !== currentItem.name);
    } else {
      return [...parentItem.childs, { ...currentItem, column: columnName }];
    }
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'Our first type',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCardHandler(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { index, name, currentColumnName, type: 'Our first type', parentCategoryId },
    type: 'CARD',
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (currentColumnName === dropResult.name) {
        return;
      }

      if (dropResult) {
        const { name } = dropResult;
        const { DO_IT, IN_PROGRESS } = COLUMN_NAMES;
        switch (name) {
          case IN_PROGRESS:
            changeItemColumn(item, IN_PROGRESS, dropResult);
            break;
          case DO_IT:
            changeItemColumn(item, DO_IT, dropResult);
            break;
          default:
            break;
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} key={index} className="movable-item" style={{ opacity }}>
      {name}
    </div>
  );
};

const MovableParentItem = ({
  name,
  index,
  currentColumnName,
  tags,
  moveCardHandler,
  setItems,
  setSelectedItems
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const changeItemColumn = (currentItem, columnName) => {
    setItems((prevState) => {
      return prevState.map((e) => {
        return {
          ...e,
          childs: e.name === currentItem.name ? [] : e.childs
        };
      });
    });
    setTimeout(() => {
      setSelectedItems((prevState) => {
        const { DO_IT } = COLUMN_NAMES;
        if (columnName === DO_IT) {
          const childs = currentItem.childs.map((cc) => {
            return { ...cc, column: columnName };
          });
          return [...prevState, ...childs];
        } else {
          return prevState.filter((x) => x.name !== currentItem.name);
        }
      });
    }, 1);
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'Our first type',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCardHandler(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { index, name, currentColumnName, type: 'Our first type', childs: tags },
    type: 'CARD',
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (currentColumnName === dropResult.name) {
        return;
      }

      if (dropResult) {
        const { name } = dropResult;
        const { DO_IT, IN_PROGRESS } = COLUMN_NAMES;
        switch (name) {
          case IN_PROGRESS:
            changeItemColumn(item, IN_PROGRESS, dropResult);
            break;
          case DO_IT:
            changeItemColumn(item, DO_IT, dropResult);
            break;
          default:
            break;
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  return (
    <div className="accordion">
      <div className="accordion-item">
        <div
          ref={ref}
          className="movable-item"
          onClick={() => setIsOpen(!isOpen)}
          style={{ opacity }}>
          <div className="accordion-title">
            <div>{isOpen ? '-' : '+'}</div>
            <div className="accordion-title-text">{name}</div>
          </div>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <ul>
              {tags.map((item, index) => (
                <li key={item.id}>
                  <MovableItem
                    name={item.name}
                    currentColumnName={item.column}
                    parentCategoryId={item.parentCategoryId}
                    setItems={setItems}
                    index={index}
                    moveCardHandler={moveCardHandler}
                    setSelectedItems={setSelectedItems}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const Column = ({ children, className, title }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'CARD',
    drop: () => ({ name: title }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    // Override monitor.canDrop() function
    canDrop: (item) => {
      const { DO_IT, IN_PROGRESS } = COLUMN_NAMES;
      const { currentColumnName } = item;
      return (
        currentColumnName === title ||
        (currentColumnName === DO_IT && title === IN_PROGRESS) ||
        (currentColumnName === IN_PROGRESS && title === DO_IT)
      );
    }
  });

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return 'rgb(188,251,255)';
      } else if (!canDrop) {
        return 'rgb(255,188,188)';
      }
    } else {
      return '';
    }
  };

  return (
    <div ref={drop} className={className} style={{ backgroundColor: getBackgroundColor() }}>
      {children}
    </div>
  );
};

export default CategoriesSelector;

import axiosInstance from './axiosInstance';

const getUserPreferenceFields = async () => {
  try {
    const response = await axiosInstance.get('/user/preferences/column?userId=4');
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const getSicCodeList = async (abortController) => {
  try {
    const response = await axiosInstance.get('/metadata/sics', {
      signal: abortController?.signal
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const getNaicsCodeList = async (abortController) => {
  try {
    const response = await axiosInstance.get('/metadata/naics', {
      signal: abortController?.signal
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const getStateFilterList = async (abortController) => {
  try {
    const response = await axiosInstance.get('/metadata/states', {
      signal: abortController?.signal
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const getCountryFilterList = async (abortController) => {
  try {
    const response = await axiosInstance.get('/metadata/countries', {
      signal: abortController?.signal
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const getCategories = async (abortController) => {
  try {
    const response = await axiosInstance.get('/metadata/parent-categories-tags', {
      signal: abortController?.signal
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const getlists = async (abortController) => {
  try {
    const response = await axiosInstance.get('/metadata/list', {
      signal: abortController?.signal
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
  }
};

const masterDataApi = {
  getSicCodeList,
  getNaicsCodeList,
  getUserPreferenceFields,
  getStateFilterList,
  getCountryFilterList,
  getCategories,
  getlists
};

export default masterDataApi;

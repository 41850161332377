import React, { Fragment } from 'react';
import './index.css';

const SuggestionsList = (props) => {
  const { suggestions, inputValue, onSelectSuggestion, displaySuggestions, selectedSuggestion } =
    props;

  if (inputValue && displaySuggestions) {
    if (suggestions.length > 0) {
      return (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => {
            const isSelected = selectedSuggestion === index;
            const classname = `suggestion ${isSelected ? 'selected' : ''}`;
            return (
              <li key={index} className={classname} onClick={() => onSelectSuggestion(index)}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <div>No suggestions available...</div>;
    }
  }
  return <></>;
};

const AutoComplete = (props) => {
  const { suggestions } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(0);
  const [displaySuggestions, setDisplaySuggestions] = React.useState(false);

  const onChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };

  const onSelectSuggestion = (index) => {
    setSelectedSuggestion(index);
    setInputValue(filteredSuggestions[index]);
    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };

  return (
    <div className="search-container">
      <div className="flex-wrapper">
        <div className="autocomplete-input">
          <input
            type="text"
            className="autocomplete-input-box"
            onChange={onChange}
            value={inputValue}
          />
          <SuggestionsList
            inputValue={inputValue}
            selectedSuggestion={selectedSuggestion}
            onSelectSuggestion={onSelectSuggestion}
            displaySuggestions={displaySuggestions}
            suggestions={filteredSuggestions}
          />
        </div>
        <div className="search-btn">
          <button type="button">{'Search Data'}</button>
        </div>
        <div></div>
        <div className="search-reset">
          <button type="button">X</button>
        </div>
      </div>
    </div>
  );
};

export default AutoComplete;

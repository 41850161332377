import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://nextgenlistbuilder-api-dev.aggdata.com',
  headers: {
    // Authorization: 'Basic dGVzdDp0ZXN0',
    // 'Content-Type': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  withCredentials: false
});

// Interceptors
// axios.interceptors.request = () => {}
// axios.interceptors.request = () => {}

export default axiosInstance;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const UserControlButtons = () => {
  const name = localStorage.getItem('name');
  const { logout } = useAuth0();
  return (
    <div className="user-control-btns">
      <div className="greet-user">Welcome: {name}</div>
      <div className="btn-wrapper">
        <a className="user-btns" href="user-btns">
          Create New List
        </a>
        <a className="user-btns" href="user-btns">
          QA-Express
        </a>
        <a className="user-btns" href="user-btns">
          Alphabatic View
        </a>
        <a className="user-btns" href="user-btns">
          Category View
        </a>
        <a className="user-btns" href="user-btns">
          More Tools
        </a>
        <a
          className="logout"
          href="logout"
          onClick={() => logout({ returnTo: window.location.origin })}>
          Logout
        </a>
      </div>
    </div>
  );
};

export default UserControlButtons;

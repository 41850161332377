// import axiosInstance from './axiosInstance';

const getUserList = async () => {
  // try {
  //   // const response = await axiosInstance.get('/user/preferences/column?userId=4');
  //   // return response.data;

  // } catch (e) {
  //   console.log('Error', e);
  // }
  return {
    data: [
      { id: 1, name: 'deepak' },
      { id: 2, name: 'abhishek' },
      { id: 3, name: 'Gaurav' },
      { id: 4, name: 'Test' }
    ]
  };
};

const userService = {
  getUserList
};

export default userService;
